// Utilities
import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
    state: () => ({
        _isSidebarOpen: false,
    }),
    getters: {
        isSidebarOpen(): boolean {
            return this._isSidebarOpen;
        },
    },
    actions: {
        setIsSidebarOpen(value: boolean) {
            this._isSidebarOpen = value;
        },
    },
});
